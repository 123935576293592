import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import React, { FC, useEffect } from "react"
import { useShoppingCart } from "use-shopping-cart"
import Layout from "../components/layout"

export const query = graphql`
  query {
    mainImage: file(name: { eq: "gren" }) {
      childImageSharp {
        id
        fluid(maxWidth: 1152, maxHeight: 420) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    senestemenu: allSanityMenu(
      sort: { fields: [aar, uge], order: [DESC, DESC] }
      limit: 1
    ) {
      nodes {
        uge
        aar
      }
    }
  }
`

const TakForOdrenPage: FC<any> = (props) => {
  const aar = props.data.senestemenu.nodes[0].aar
  const uge = props.data.senestemenu.nodes[0].uge
  const { clearCart } = useShoppingCart()

  useEffect(() => {
    clearCart()
  }, [clearCart])

  return (
    <Layout title="Tak for bestillingen" aar={aar} uge={uge}>
      <Img fluid={props.data.mainImage.childImageSharp.fluid} />
      <div className="max-w-3xl px-8 mx-auto">
        <h1>Tak for bestillingen!</h1>
        <p>
          Har du spørgsmål til din ordre så{" "}
          <Link className="underline" to="/kontakt/">
            kontakt os her
          </Link>
        </p>
      </div>
    </Layout>
  )
}

export default TakForOdrenPage
